import { ChevronRightBold } from '@pretto/picto'

import * as S from './CardBlack.styles'

export interface CardBlackProps {
  duration: number
  href: string
  value: number
}

export const CardBlack = ({ duration, href, value, ...props }: CardBlackProps) => {
  return (
    <S.CardBlack href={href} {...props}>
      <S.FirstRow>
        Taux sur <strong>{duration} ans</strong>
      </S.FirstRow>
      <S.SecondRow>
        <S.Value>
          {value.toLocaleString('fr-FR')}%<sup>*</sup>
        </S.Value>
        <S.CTA>
          <ChevronRightBold />
        </S.CTA>
      </S.SecondRow>
    </S.CardBlack>
  )
}
