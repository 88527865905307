import { g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'
import Link from '@pretto/bricks/website/utility/Link'

import styled from 'styled-components'

export const CardBlack = styled(Link)`
  display: block;
  padding: ${g(2)} ${g(2)} ${g(3)} ${g(3)};
  border-radius: ${g(1)};
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.white};
  transition-property: box-shadow, transform;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;

  &:hover {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    transform: translateY(-6px);
  }
`

export const FirstRow = styled.div`
  ${typo.bodyBook16};
  margin-bottom: ${g(1)};
`

export const SecondRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Value = styled.div`
  ${typo.numbers32}
`

export const CTA = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${g(4)};
  width: ${g(4)};
  background-color: ${({ theme }) => theme.colors.neutral1};
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${g(1, -4)};
`
